<template>
  <el-dialog
    v-model="visible"
    :title="'code' in needUpdateMemberCardInfo ? '编辑会员卡等级' : '新增会员卡等级'"
    width="70%"
    :before-close="handleClose"
  >
    <el-form :model="needUpdateMemberCardInfo" :rules="rules" ref="formRef">
      <el-form-item label="会员卡等级编号" label-width="130px" prop="level">
        <el-input
          :maxlength="10"
          placeholder="请输入数字编号"
          v-model="needUpdateMemberCardInfo.level"
          :disabled="'code' in needUpdateMemberCardInfo"
        ></el-input>
      </el-form-item>
      <el-form-item label="会员卡等级名称" label-width="130px" prop="name">
        <el-input placeholder="请输入最多20个字" :maxlength="20" v-model="needUpdateMemberCardInfo.name"></el-input>
      </el-form-item>
      <el-form-item label="LOGO" label-width="120px">
        <p class="remark">尺寸推荐300px * 300px且小于1M，文件格式为png、jpg、jpeg</p>
        <UploadImg
          reef="logoImg"
          :imageSrc="needUpdateMemberCardInfo.logo"
          propName="logo"
          @uploadImage="uploadImage"
        ></UploadImg>
      </el-form-item>
      <el-form-item label="背景图" label-width="130px">
        <p class="remark">尺寸推荐1000px * 600px且小于1M，文件格式为png、jpg、jpeg</p>
        <UploadImg :imageSrc="needUpdateMemberCardInfo.pic" propName="pic" @uploadImage="uploadImage"></UploadImg>
      </el-form-item>
      <el-form-item label="卡有效期" label-width="130px" prop="termOfValidity">
        <tabBar
          :data="termOfValidity"
          @clickTab="clickTermOfValidity"
          :activeTab="needUpdateMemberCardInfo.termOfValidity"
        ></tabBar>
        <div v-show="needUpdateMemberCardInfo.termOfValidity !== 1" style="display: flex; margin-top: 10px;">
          <label style="width: 100px;">升级后</label>
          <el-input v-model="needUpdateMemberCardInfo.effectiveYears" :maxlength="4">
            <template #suffix>
              年
            </template>
          </el-input>
        </div>
      </el-form-item>
      <el-form-item label="卡权益" label-width="130px" prop="roomDiscount">
        <el-row :gutter="20">
          <el-col :span="10">
            <label>包厢折扣</label>
            <el-input placeholder="例填写8.8表示打8.8折" v-model="needUpdateMemberCardInfo.roomDiscount">
              <template #suffix>
                折
              </template>
            </el-input>
          </el-col>
          <el-col :span="10">
            <label>商品折扣</label>
            <el-input v-model="needUpdateMemberCardInfo.goodsDiscount" disabled>
              <template #suffix>
                折
              </template>
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="积分" label-width="130px">
        <el-row :gutter="20">
          <el-col :span="10">
            <label>每消费</label>
            <el-input v-model="needUpdateMemberCardInfo.accAmountPoints" disabled>
              <template #suffix>
                元
              </template>
            </el-input>
          </el-col>

          <el-col :span="10">
            <label>获取积分</label>
            <el-input v-model="needUpdateMemberCardInfo.accAmountPointsX" disabled>
              <template #suffix>
                分
              </template>
            </el-input>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px;" :gutter="20">
          <el-col :span="12"></el-col>
          <el-col :span="10">
            <span>(限微信、支付宝、pos刷卡、预定单微信支付)</span>
          </el-col>
        </el-row>
        <el-row style="margin-top: 5px;" :gutter="20">
          <el-col :span="10">
            <label>每充值</label>
            <el-input v-model="needUpdateMemberCardInfo.storedAmountPoints" disabled>
              <template #suffix>
                元
              </template>
            </el-input>
          </el-col>
          <el-col :span="10">
            <label>获取积分</label>
            <el-input v-model="needUpdateMemberCardInfo.storedAmountPointsX" disabled>
              <template #suffix>
                分
              </template>
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="升降级" label-width="130px" prop="moneny">
        <el-row>
          <el-col :span="12">
            <label style="width: 150px;">单次充值金额</label>
            <el-input v-model="needUpdateMemberCardInfo.storedAmount" :maxlength="6">
              <template #suffix>
                元
              </template>
            </el-input>
          </el-col>
          <el-col :span="12">
            <label style="width: 150px;">累计消费金额</label>
            <el-input v-model="needUpdateMemberCardInfo.accAmount" :maxlength="6" disabled>
              <template #suffix>
                元
              </template>
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="生日礼遇" label-width="130px" class="coupou" prop="birthdayCoupons">
        <el-button @click="openProductDialog('birthdayCoupons')">添加优惠卷</el-button>
        <coupouTable
          :data="needUpdateMemberCardInfo.birthdayCoupons"
          @udateCoupou="udateCoupou"
          propName="birthdayCoupons"
        />
      </el-form-item>
      <!-- <el-form-item label="开卡/升级礼遇" label-width="130px" class="coupou" prop="upCoupons">
        <el-button @click="openProductDialog('upCoupons')">添加优惠卷</el-button>
        <coupouTable :data="needUpdateMemberCardInfo.upCoupons" @udateCoupou="udateCoupou" propName="upCoupons" />
      </el-form-item> -->
      <el-form-item label="每月赠券" label-width="130px" class="coupou" prop="monthCoupons">
        <el-button @click="openProductDialog('monthCoupons')">添加优惠卷</el-button>
        <coupouTable :data="needUpdateMemberCardInfo.monthCoupons" @udateCoupou="udateCoupou" propName="monthCoupons" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="onhandleSubmit">
          保存
        </el-button>
      </span>
    </template>
    <SelectCoupou
      v-if="coupouDialogVisible"
      :dialogVisible="visible"
      @update-dialogVisible="rectiveProducts"
    ></SelectCoupou>
  </el-dialog>
</template>

<script setup>
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref, defineProps, defineEmit, watchEffect } from 'vue'
import UploadImg from '../../component/uploadImg.vue'
import SelectCoupou from '../../component/select-coupou.vue'
import tabBar from '../../component/tab-bar.vue'
import coupouTable from '../../component/coupou-table.vue'
import API from '../../service/index'
const props = defineProps({
  /*需要操作的会员卡信息 */
  needUpdateMemberCardInfo: {
    type: Object,
    default: {},
  },
})

const emit = defineEmit(['handleCloseDialog'])

const visible = ref(true)

const handleClose = () => {
  emit('handleCloseDialog', false)
}

/**卡有效期 */
const termOfValidity = ref([])
watchEffect(() => {
  if (props.needUpdateMemberCardInfo.isDefault === 1) {
    termOfValidity.value = [
      {
        code: 1,
        name: '永久有效',
      },
    ]
  } else {
    termOfValidity.value = [
      {
        code: 1,
        name: '永久有效',
      },
      {
        code: 2,
        name: '相对有效期',
      },
    ]
  }
})

/**
 * 改变图片
 * @param {*} data
 */
const uploadImage = data => {
  if (data.imageUrl === undefined) {
    props.needUpdateMemberCardInfo[data.propName] = ''
  } else {
    let { imageUrl, propName } = data
    props.needUpdateMemberCardInfo[propName] = imageUrl
  }
}

/**
 * 改变有效期
 * @param {*} param0
 */
const clickTermOfValidity = ({ code }) => {
  props.needUpdateMemberCardInfo.termOfValidity = code
}

// 选择优惠卷
const coupouDialogVisible = ref(false) //商品选择组件展示

/**
 * 接受新数据
 * @param {object} item 选择的商品
 */
const rectiveProducts = ({ selectSkus }) => {
  isShowCoupouDialog(false)
  props.needUpdateMemberCardInfo[cardType.value] = props.needUpdateMemberCardInfo[cardType.value] || []
  injectNewProduct(props.needUpdateMemberCardInfo[cardType.value], selectSkus)
}

/**
 * 注入新数据
 * @param {array} oldProducts  老数据
 * @param {array} newProducts  新数据
 */
const injectNewProduct = (oldProducts, newProducts) => {
  newProducts.forEach(n => {
    let old = oldProducts.find(o => o.code === n.code)
    if (old) {
      old.ruleSetNum++
    } else {
      oldProducts.push({
        ...n,
        ruleCode: n.code,
        ruleSetNum: 1,
      })
    }
  })
}

/**
 * 更新优惠卷
 * @param {*} obj
 */
const udateCoupou = obj => {
  let { data, propName } = obj
  props.needUpdateMemberCardInfo[propName] = data
  if (data.length <= 10) {
    formRef.value.validateField(propName)
  }
}

const cardType = ref('')
/**
 * 打开优惠卷dialog
 * @param {*} type
 */
const openProductDialog = type => {
  cardType.value = type
  isShowCoupouDialog(true)
}

/**
 * 是否展示选择优惠卷ialog
 */
const isShowCoupouDialog = val => {
  coupouDialogVisible.value = val
}

const validateTermOfValidity = (rule, value, callback) => {
  if (props.needUpdateMemberCardInfo.termOfValidity !== 1) {
    if (!props.needUpdateMemberCardInfo.effectiveYears) {
      return callback(new Error('请填写相对有效期时间'))
    } else if (!/^\d+$/.test(props.needUpdateMemberCardInfo.effectiveYears)) {
      return callback(new Error('相对有效期必须是整数'))
    }
  }
  callback()
}

const validateRoomDiscount = (rule, value, callback) => {
  if (!props.needUpdateMemberCardInfo.roomDiscount) {
    return callback()
  }
  if (!/^\d+(\.\d)?$/.test(props.needUpdateMemberCardInfo.roomDiscount)) {
    return callback(new Error('包厢折扣必须是一位小数或整数'))
  }
  if (props.needUpdateMemberCardInfo.roomDiscount > 10) {
    return callback(new Error('包厢折扣不能大于10'))
  }
  return callback()
}

const validateLevel = (rule, value, callback) => {
  if (!props.needUpdateMemberCardInfo.level) {
    return callback(new Error('请填写会员卡等级编号'))
  }
  if (!/^\d+$/.test(props.needUpdateMemberCardInfo.level)) {
    return callback(new Error('会员卡等级编号是数字'))
  }
  return callback()
}

/**
 * 校验升降级数据
 */
const validateMoneny = (rule, value, callback) => {
  let storedAmount = props.needUpdateMemberCardInfo.storedAmount
  let accAmount = props.needUpdateMemberCardInfo.accAmount
  if (storedAmount && !/^\d+$/.test(storedAmount)) {
    return callback(new Error('单次充值金额必须是整数'))
  }
  // if (accAmount && !/^\d+$/.test(accAmount)) {
  //   return callback(new Error('累计消费金额必须是整数'))
  // }
  return callback()
}

/**
 * 校验优惠卷数量
 */
const validateCoupons = (rule, value, callback) => {
  if (!value || value.length <= 10) {
    return callback()
  } else {
    return callback(new Error('优惠券种类不能超过10'))
  }
}

const formRef = ref(null)
const rules = {
  level: [{ required: true, validator: validateLevel, trigger: 'change' }],
  name: [{ required: true, message: '请填写会员卡等级名称', trigger: 'change' }],
  termOfValidity: [{ required: true, validator: validateTermOfValidity, trigger: 'change' }],
  roomDiscount: [{ required: false, validator: validateRoomDiscount, trigger: 'change' }],
  moneny: [{ required: false, validator: validateMoneny, trigger: 'change' }],
  birthdayCoupons: [{ required: false, validator: validateCoupons, trigger: 'change' }],
  monthCoupons: [{ required: false, validator: validateCoupons, trigger: 'change' }],
  upCoupons: [{ required: false, validator: validateCoupons, trigger: 'change' }],
}

const onhandleSubmit = async () => {
  try {
    await formRef.value.validate()
    let res = await API.levelSave(props.needUpdateMemberCardInfo)
    if (res.code === '200') {
      ElMessage.success('操作成功')
      handleClose()
    } else {
      ElMessage.error(res.msg)
    }
  } catch (error) {
    console.log(error)
    ElMessage.warning('请完成必要信息填写')
  }
}
</script>

<style scoped lang="scss">
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 130px !important;
  }
}
.coupou {
  ::v-deep(.el-form-item__content) {
    width: 70%;
  }
}
.remark {
  color: rosybrown;
}
.coupon-table {
  margin-top: 5px;
}
::v-deep(.el-col) {
  display: flex;
  label {
    width: 120px;
    text-align: right;
    margin-right: 5px;
  }
}
</style>
